import React, { useContext, useEffect } from 'react'
import LocaleContext from 'context/LocaleProvider'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getQuery } from 'functions/getQuery'
import GenericPagesLayout from 'components/PagesLayout/GenericPages'
import { withPreview } from 'gatsby-source-prismic'

const GenericPages = props => {
  const { data, children } = props
  const globalContentQuery = getQuery(['global', 'nodes', 0, 'data'], data)
  const lang = useContext(LocaleContext)

  useEffect(() => {
    lang?.setPageDetails({
      uid: props?.pageContext?.alternativeLanguageUid,
      type: 'page',
    })
  }, [])

  return (
    <GenericPagesLayout query={data} globalQuery={globalContentQuery}>
      {children}
    </GenericPagesLayout>
  )
}

GenericPages.propTypes = {
  query: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
}

export default withPreview(GenericPages)

export const genericPageTemplateQuery = graphql`
  query genericPageQuery($uid: String!, $locale: String!) {
    footer: allPrismicFooter {
      nodes {
        lang
        data {
          footer_items {
            page_type
            footer_item_link {
              uid
            }
            footer_item_label {
              text
            }
          }
          newsletter_input_component {
            document {
              ... on PrismicNewsletterInput {
                id
                data {
                  input_success_message {
                    text
                  }
                  input_placeholder {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    legalFooter: allPrismicLegalFooter {
      nodes {
        lang
        data {
          legal_footer_items {
            page_type
            legal_item_label {
              text
            }
            legal_item_link {
              uid
            }
          }
          copyright_text {
            copyright {
              text
            }
          }
        }
      }
    }
    global: allPrismicGlobalContent(filter: { lang: { eq: $locale } }) {
      nodes {
        data {
          scroll_down_label {
            text
          }
          read_more {
            text
          }
        }
      }
    }
    allPrismicGenericTemplate(
      filter: { uid: { eq: $uid }, lang: { eq: $locale } }
    ) {
      nodes {
        id
        uid
        lang
        data {
          seo_component {
            document {
              __typename
              ... on PrismicSeo {
                id
                data {
                  breadcrumb_title {
                    text
                  }
                  open_graph_title {
                    text
                  }
                  open_graph_description {
                    text
                  }
                  open_graph_image {
                    alt
                    localFile {
                      url
                    }
                  }
                  seo_title {
                    text
                  }
                  seo_description {
                    text
                  }
                }
              }
            }
          }
          hero_title {
            text
          }
          hero_body {
            text
          }
          generic_title {
            text
            html
          }
          generic_content {
            text
            html
          }
        }
      }
    }
  }
`
